import React from "react"
import Layout from "../../components/layout"
import { Link } from "gatsby"
import SEO from "../../components/seo"
import { Container, Row, Col, } from "react-bootstrap"
import dowDoc from "../../images/download-doc.png"
import smartboxdetail from "../../images/smart-box-detail.webp"
import smartBoxDoc from '../../docs/Smart_Box.pdf'

class Smartbox extends React.Component {
  constructor(props) {
    super(props);
    this.state = { isToggleOn: true };
    this.handleClick = this.handleClick.bind(this);
  }
  handleClick() {
    this.setState(state => ({
      isToggleOn: !state.isToggleOn
    }));
  }

  render() {
    return <Layout>
      <SEO title="Smart Box | Laundry 4.0 Smart Controller" 
      description="Proalcs's compact data collection solution designed for Laundry monitoring."/>
      <Container>
        <div className='prolacs-divider-title' style={{ marginTop: '0', backgroundColor: '#FFFFFF', display: 'flex', alignItems: 'baseline' }}>
          <br></br>
          <br></br>
          <Link className='link-breadcrumb' to='/products'><h2 style={{ fontSize: '19px' }}>Products /</h2> </Link>
          <h1 style={{ fontSize: '20px', paddingLeft: '5px' }}> Smart Box </h1>
        </div>
        <Row>
          <Col sm className='product-detail-head'>
            <div className={`product-detail-header-image´  ${this.state.isToggleOn ? 'product-detail-header-image' : 'product-detail-header-image-zoom'}`} >
              <img className='product-detail' onClick={this.handleClick} src={smartboxdetail} alt="smart-box-prolacs" />
            </div>
          </Col>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='controllerheadtext' style={{width:"100%"}} >Smart Box</h1>
            </div>
            <h2>Laundry 4.0 Smart Controller</h2>
            <p>It is a compact data collection solution designed for Laundry monitoring. With Sbox, you can monitor data, define alarms, analyze data and receive notifications via SMS / E-Mail. With the data backup specification, you can save all historical data. It is a plug & play solution that does not require any computer or software for installation. Compatible with all kinds of systems with wide communication protocols.</p>
          </Col>

        </Row>
        <Row>
          <Col sm className='product-detail-title'>
            <div >
              <h1 className='product-detail-features-header'>Documents</h1>
            </div>
            <br></br>
            <Row>
              <Col className='product-detail-doc-name'>
                <a target="_blank" rel="noopener noreferrer"  href={smartBoxDoc} >  <img className='product-detail-doc' src={dowDoc} alt="prolacs smart box" />
                  <h1>Smart Box</h1>  </a>
              </Col>
            </Row>
          </Col>
        </Row>

        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1>Specifications</h1>
        </div>
        <Row>
          <Col sm>
            <li> Web based interface </li>
            <li>Customizable dashboard </li>
            <li>Alarm-Notification management </li>
            <li>Rule management </li>
            <li>Monitoring from the center </li>
            <li>Consumption monitoring </li>
            <li>Real-time tracking (Trend and Table) </li>
            <li>Reporting </li>
            <li>Downtime tracking </li>
            <li> Production counter </li>
            <li> PLC Communication support </li>

          </Col>
          <Col sm>
            <li> Modbus TCP/RTU communication support</li>
            <li> OPC UA client support</li>
            <li> 4-20mA sensor support</li>
            <li> PT100 sensor support</li>
            <li> Dry contact sensor input (4pcs)</li>
            <li> Alarm siren output</li>
            <li> Relay output (2 pcs)</li>
            <li> Built in Wi-Fi </li>
            <li> 3G modem support </li>
            <li> IP/USB camera support</li>
            <li> Onboard temperature and humidity sensors</li>
          </Col>
        </Row>



        <div className='prolacs-divider-title'>
          <br></br>
          <br></br>
          <h1 >Features</h1>
        </div>
        <div >
          <h1 className='product-detail-features-header'>Technical Specifications</h1>
        </div>

        <Row className="product-table-row">
          <Col>
            <p>Internal Temperature and Humidity Sensor</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Internal Temperature Sensor Measuring Range</p>
          </Col>
          <Col className="lastp">
            <p>0 - 60 °C</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Internal Temperature Sensor Sensitivity</p>
          </Col>
          <Col className="lastp">
            <p>Precision  0.5% </p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Internal Humidity Sensor Measuring Range</p>
          </Col>
          <Col className="lastp">
            <p>Relative Humidity 0 - 100</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Humidity Sensor Sensitivity</p>
          </Col>
          <Col className="lastp">
            <p>Sensitivity  3%</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Sensor Port (1-Wire)</p>
          </Col>
          <Col className="lastp">
            <p>1 (Max. 16 pcs 1-Wire Sensor)</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Max. 1-Wire Sensor Distance</p>
          </Col>
          <Col className="lastp">
            <p>50 m</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Memory</p>
          </Col>
          <Col className="lastp">
            <p>8 GB</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Processor</p>
          </Col>
          <Col className="lastp">
            <p>4-Core ARM</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Input</p>
          </Col>
          <Col className="lastp">
            <p>4 (5 - 24 VDC)</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Digital Output</p>
          </Col>
          <Col className="lastp">
            <p>2 (Max. 30 VDC)</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>USB 2.0 Port</p>
          </Col>
          <Col className="lastp">
            <p>3</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>HDMI Output</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>10/100 Mb Ethernet</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Serial Communication (RS-485)</p>
          </Col>
          <Col className="lastp">
            <p>1</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>External HDD, Keyboard, Mouse Connection</p>
          </Col>
          <Col className="lastp">
            <p>USB</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Supply Voltage</p>
          </Col>
          <Col className="lastp">
            <p>24 VDC</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Power Consumption</p>
          </Col>
          <Col className="lastp">
            <p>10 W</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Temperature Range</p>
          </Col>
          <Col className="lastp">
            <p>0 - 60 ° C</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Operating Humidity Range</p>
          </Col>
          <Col className="lastp">
            <p>10% - 90% (Non-condensing)</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Physical Dimensions </p>
          </Col>
          <Col className="lastp">
            <p>60x90x85 mm</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Mounting Type</p>
          </Col>
          <Col className="lastp">
            <p>DIN Rail</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>EMC Compatibility</p>
          </Col>
          <Col className="lastp">
            <p>EN 61000-6-4 / EN 61000-6-2</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Product Warranty</p>
          </Col>
          <Col className="lastp">
            <p>1 Year</p>
          </Col>
        </Row>
        <Row className="product-table-row">
          <Col>
            <p>Network Protocols</p>
          </Col>
          <Col className="lastp">
            <p>HTTP, HTTPS, SNMP Traps, TCP Socket IO, MQTT, Modbus TCP, Modbus RTU, OPC UA</p>
          </Col>
        </Row>


      </Container >
    </Layout >
  }
};
export default Smartbox
